/*styles for improved contact page*/
.contact-us-container {
  position: relative;
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #004080; /* Text color */
  text-align: center;
  padding: 20px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-image: url('/src/components/images/library.jpg'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  opacity: 0.5; /* Adjust opacity as needed */
}

.animated-text {
  font-size: 5rem; /* Adjust font size as needed */
  margin-bottom: 20px; /* Adjust spacing */
  font-weight: bolder;
}

.animated-text2 {
  font-size: 2rem; /* Adjust font size as needed */
  margin-bottom: 20px; /* Adjust spacing */
  font-weight: bold;
}

.static-text {
  font-size: 2rem; /* Adjust font size as needed */
  margin-bottom: 20px; /* Adjust spacing */
  font-weight: bold;
}

.sub-text {
  font-size: 1.5rem; /* Adjust font size as needed */
  font-weight: bold;
}