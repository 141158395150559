body {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}

.begin {
  font-family: 'Poppins', sans-serif;
  width: auto;
  overflow-x: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Headings {
  height: 50px;
  background-color: #808080;
}

.Headings h2 {
  font-size: 24px;
  color: #ffd700;
  font-weight: bold;
  text-align: center;
}

.logo img {
  height: 70px;
  width: 320px;
}

.nav {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 8px;
}

.nav-item {
  list-style: none;
  font-family: 'Poppins', sans-serif;
  margin-left: 20px;
  margin-top: 15px;
  font-weight: 500;
}

.nav-item a:hover {
  color: goldenrod !important;
}

.nav-link {
  text-decoration: none;
  color: #000; 
  font-weight: bold;
  font-size: 14px;
  padding: 10px 15px; 
  display: inline-block;
}

.nav-link:hover {
  color: goldenrod;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  font-size: 12px;
  z-index: 1000;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown li {
  white-space: nowrap;
  padding: 0px 20px;
  margin: 0px;
}

.dropdown li a {
  text-decoration: none;
  color: #000;
}

/* Adjusted for submenu handling */
.navbar-nav .dropdown-submenu {
  position: relative;
}

.navbar-nav .dropdown-submenu .dropdown-menu {
  display: none; 
  position: absolute;
  top: 0;
  left: 100%; 
  margin-top: -1px; 
}

.navbar-nav .dropdown-submenu:hover .dropdown-menu {
  display: block; 
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.phone-and-buttons {
  display: flex;
  align-items: center;
  gap: 0px;
  flex-direction: row;
}

.button-group .phone-number {
  font-size: 15px;
  color: #000;
  margin-top: 10px;
}

.button-group {
  display: flex;
  gap: 0px;
  width: 100%;
}

.btns {
  display: inline-block;
  padding: 7px 10px;
  background: goldenrod;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  font-size: 15px;
  border: 0;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 140px;
  text-align: center;
  box-sizing: border-box;
}

.btns:hover {
  background-color: #004080;
}

.btnz {
  display: inline-block;
  padding: 7px 10px;
  background: #004080;
  color: #fff;
  text-decoration: none;
  border-radius: 6px;
  font-size: 15px;
  border: 0;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 140px;
  text-align: center;
  box-sizing: border-box;
}

.btnz:hover {
  background-color: #003060;
}

.actions input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #0c0c0c;
  border-radius: 4px;
  width: 100%; 
}

.d-flex {
  width: 100%; 
}

.me-2 {
  margin-top: 0;
}

.btn-outline-success {
  margin-top: 0;
}

.hero1, .hero2, .hero3,.hero4, .hero5 {
  position: relative;
  height: 560px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
}

.hero1 {
  background-image: url('/src/components/images/Students Happy.jpg');
}

.hero2 {
  background-image: url('/src/components/images/university class.jpg');
}
.hero3 {
  background-image: url('/src/components/images/library.jpg');
}
.hero4 {
  background-image: url('/src/components/images/students in a school.jpg');
}
.hero5 {
  background-image: url('/src/components/images/readingabook.jpg');
}

.hero-content {
  background: rgba(0, 0, 0, 0.5);
  height: 560px;
}

.heros{
  text-align: left;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  width: 500px; 
  position: absolute;
  top: 60%; 
  left: 170px;
  transform: translateY(-50%);
  z-index: 1;
}

.donate {
  text-align: center;
  color: #ffffff;
  padding: 20px 20px;
  max-width: 400px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
}

.hero-content h1 {
  margin: 0;
  font-size: 35px;
  color: goldenrod;
  font-weight: bold;
}

.donate h1 {
  margin: 0;
  font-size: 40px; 
  color: orange;
  font-weight: bolder;
}
.hero-content p, .donate p {
  font-size: 25px;
  font-family: 'Poppins', 'sans-serif';
}

.hero-content .btn1, .donate .btn1 {
  padding: 8px 16px;
  background: #004080;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  display: inline-block;
  margin-top: 10px;
  font-size: 18px;
}

.hero-content .btn1:hover, .donate .btn1:hover {
  background: #003060;
}
.middle-section {
  display: flex;
  position: relative;
  margin: 0 auto;
  width: 80%;
  margin-top: 100px;
}

.middle-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('/src/components/images/Bhu-logo-wiki.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.4; 
  z-index: -1;
}

.study {
  width: 70%; /* Adjust as needed for width */
  position: relative;
  
}

.study .image {
  width: 100%;
  height: 300px; /* Increase height to fit content */
  object-fit: cover;
  display: block;
  border-radius: 30px;
}

.study h3, .research h3 {
  color: goldenrod;
  font-size: 30px;
  font-weight: bold;
}
.study h3:hover, .research h3:hover{
  color: rgb(255, 167, 4);
}

.study .content, .research .content {
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  text-align: center;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 30px;
}
.study .content ul li :hover, .research .content  ul li :hover{
  color: rgb(255, 167, 4);
}

.research {
  margin-top: 170px;
  z-index: 2;
  margin-left: -80px;
}

.study {
  z-index: 0; 
  height: 300px;
  margin-left: 100px;

}

.research .content {
  top: 0;
}

.research li, .study li{
  list-style: none;
}

.link-style {
  text-decoration: none;
  color: inherit;
}

/* student life section*/
.student-life-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  margin-top: 100px;
}

.student-life-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('/src/components/images/Bhu-logo-wiki.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.4;
  z-index: -1;
}

.student-life-image {
  width:800px;
  margin-right: 80px;
  margin-left: -50px;
}

.student-life-content {
  margin-left: 50px;
  width: 90%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}

.student-life-content h3 {
  color: goldenrod;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.student-life-content h5 {
  color: goldenrod;
  font-weight: bold;
  margin-top: 3px;
}

.student-life-content p {
  color: white;
font-size: 13px;
}

.content-row {
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
}

.content-row .col-md-6 {
  flex: 1 1 calc(50% - 20px); 
}

.col-md-12 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  color: white;
}

.col-md-12 h5 {
  color: goldenrod;
  font-weight: bold;
  margin-top: 0; /* Remove default margin */
  justify-content: start;
}

.col-md-12 p {
  font-size: 13px;
  justify-content:center; /* Justify text */
  margin-left: 25%;
  margin-right: 20%;
}

/* Undergraduate section */
.homelink{
  text-decoration: none;
}

/*courses section*/
.App {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
}

.App-header {
  color: black;
  text-align: center;
}

.App-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  margin: 20px;
  width: 300px;
  background-color: #fff;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.date {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

.month {
  font-size: 14px;
  color: #666;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: goldenrod;
}

.card-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  margin-bottom: 10px;
}

.card-content {
  padding: 20px;
}

.top-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -130px;
  background-color: #fff;
}

.learn-how-to {
  font-size: 16px;
  font-weight: bold;
  background-color: white;
}

.list {
  font-size: 14px;
  color: #666;
  margin-right: 120px;
  margin-top: 20px;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
  border-style: none;
}

.card-footer2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  flex-direction: column;
}
.date-time {
  font-size: 14px;
  color: #666;
  margin-right: 10px;
}

.date-time2 {
  font-size: 14px;
  color: #666;
  margin-right: 30px;
}

.location {
  font-size: 14px;
  color: #666;
  margin-right: 120px;
  margin-top: 20px;
}

.calendar-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

.time-image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 20px;
}

.coursebtn {
  background-color:#004080;
  color: #fff;
  border: none;
  padding: 13px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
 border-radius: 5px;
}

button:hover {
  background-color:#003060;
}

/* Css for InquirePage */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid blue; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  border-bottom: 16px solid blue;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Reset some basic styles */
body, h2, label, input, select, textarea, form {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

body {
  background-color: #f5f5f5;
  color: #333;
}

/* ProfilePage.css */
body, h1, h2, p, img, div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

body {
  background-color: #f5f5f5;
  color: #333;
}

/* Profile page container */
.profile-page {
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  overflow-x: hidden;
}

/* Header styling */
.profile-header {
  background-color: #004080;
  padding: 1rem;
  text-align: start;
  font-size: x-large;
  color: #fff;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  position: relative;
  margin-top: -40px;
}

.profile-header h1 {
  color: #fff;
  font-size: 2rem;
}

/* Profile content container */
.profile-content {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  gap: 50px;
}

/* Profile image styling */
.profile-image {
  flex: 1;
  max-width: 300px;
  border-radius: 8px;
  height: 300px;
}

/* Profile text styling */
.profile-text {
  flex: 2;
  margin-left: 2rem;
}

.profile-text h2 {
  font-size: 1.5rem;
  color: #004080;
  margin-bottom: 1rem;
}

.profile-text p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

/* Back button styling */
.back-button-container {
  margin-top: 2rem;
  text-align: center;
}

.back-button {
  display: inline-block;
  background-color: #004080;
  color: #fff;
  padding: 1rem 2rem;
  text-decoration: none;
  font-weight: bold;
  border-radius: 4px;
  position: relative;
  transition: background-color 0.3s ease;
}

.back-button .arrow {
  margin-right: 0.5rem;
}

.back-button:hover {
  background-color: #003060;
}

.back-button::after {
  content: '';
  display: block;
  width: 100%;
  height: 4px;
  background-color: #ffd700;
  position: absolute;
  bottom: -4px;
  left: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* Form styles */
.inquire-form {
  background-color: #fff;
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-heading {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #0056b3;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-input,
.form-select,
.form-textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.form-submit {
  background-color: #004080;
  color: #fff;
  padding: 7px 10px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 15px;
  width: 140px;
  text-align: center;
  font-weight: bold;
}

.form-submit:hover {
  background-color: #003060;
}

/* Optional: Enhance accessibility */
.form-input:focus,
.form-select:focus,
.form-textarea:focus {
  border-color: #0056b3;
  outline: none;
}

.form-submit:focus {
  outline: none;
  box-shadow: 0 0 3px 2px rgba(0, 86, 179, 0.5);
}
.phoneInputGroup {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.countryCodeSelect {
  flex: 1;
  margin-right: 0.5rem;
}

.phoneNumberInput {
  flex: 2;
  margin-top: 10px;
}

/* Footer Styles*/
footer {
  background-color: #004080;
  color: #fff;
  padding: 20px 120px;
  display: flex;
  flex-direction: column;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.contact, .address, .links {
  width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
}

.contact h4, .address h4, .links h4 {
  font-size: 14px;
}

.contact ul, .address ul, .links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contact li, .address li, .links li {
  margin-bottom: 10px;
  font-size: 14px;
}

.contact a, .address a, .links a {
  color: #fff;
  text-decoration: none;
}

.contact a:hover, .address a:hover, .links a:hover {
  color: goldenrod;
}

.subscribe {
  width: 150%;
  margin-right: -140px;
}

.subscribe h4 {
  font-size: 14px;
  margin-bottom: 20px;
}

.subscribe input[type="email"] {
  padding: 8px;
  font-size: 14px; /* Adjust font size as needed */
  border: 1px solid #0c0c0c;
  border-radius: 4px;
  width: 80%; /* Adjust width as needed */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.subscribe button {
  display: block; /* Ensure the button is treated as a block element */
  width: 60%; /* Adjust the width to be less than the input width */
  padding: 8px 10px;
  font-size: 14px; /* Match font size with the input field */
  border: none;
  border-radius: 4px;
  background-color: goldenrod; /* Button background color */
  color: white;
  cursor: pointer;
  margin: 8px auto 0; /* Center button horizontally and add space above it */
}

.subscribe button:hover {
  background-color: orange; /* Darker shade for hover effect */
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.social-media {
  width: 50%;
  margin-right: 20px;
}

.social-media ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-media li {
  margin-bottom: 10px;
}

.social-media a {
  color: #fff;
  text-decoration: none;
}

.social-media a:hover {
  color: #ccc;
}

.copyright {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.copyright p {
  font-size: 14px;
  margin-top: 0px;
}

.logo-bottom img {
  height: 250px;
  width: 350px;
  margin-left: 900px;
  margin-top: -190px;
  margin-bottom: 40px;
}

.image img,
.imgs img,
.images img {
    height: 420px;
    width: 525px;
    border-radius: 10px;  
}

.imagez img {
    height: 600px;
    width: 360px;
    border-radius: 10px;  
}

.partnertxt{
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  width: 60%;
  height: 100%;
}

.partnertxt p{
  text-align: justify;
}

.panel-heading h1 {
  color: #ffd700;
  font-weight: bold;
  text-align: start;
}

.d-text {
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.d-text p {
 text-align: center;
}

.d-text h3 {
  text-align: center;
  color: #004080;
  font-size: 18px;
  font-weight: bold;
}

.col-md-4 h3,
.col-md-5 h3,
.col-md-6 h3 {
  color: #004080;
  font-size: 18px;
  font-weight: bold;
}

/*styles for contacts page*/
.middle-section1 {
  display: flex;
  justify-content: space-between;
  padding: 50px 100px;
  background-color: #f7f7f7;
}

.studyie,.researchs {
  width: 45%;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.studyie.contents,.researchs.contents {
  padding: 20px;
}

.studyie h3,.researchs h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.studyie ul,.researchs ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.studyie li,.researchs li {
  margin-bottom: 10px;
}

.studyie a,.researchs a {
  color: #337ab7;
  text-decoration: none;
}

.studyie a:hover,.researchs a:hover {
  color: #23527c;
}